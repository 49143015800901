<script>
import { authMethods, notificationMethods } from '@/state/helpers';
import Layout from '../../layouts/auth';
import { validationMixin } from '@/mixins/form/validation';
import { ErrorMessage, Field, Form } from 'vee-validate';
import brandMixin from '@/mixins/brand.mixin';

/**
 * Register component
 */
export default {
  mixins: [validationMixin, brandMixin],
  components: {
    Layout,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loading: false,
      user: {
        email: '',
        phone: '',
        first_name: '',
        last_name: '',
        password: '',
        password_confirmation: '',
      },
      regErrorText: null,
      appName: process.env.VUE_APP_NAME,
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    // Try to register the user in with the email, username
    // and password they provided.
    tryToRegisterIn(values, actions) {
      this.loading = true;
      this.regErrorText = null;

      this.register(this.user)
        .then(() => {
          this.$router.push(
            this.$route.query.redirectFrom || {
              name: 'home',
            }
          );
        })
        .catch(({ response }) => {
          this.regErrorText = this.handleResponseErrors(response, { values, actions });
          this.loading = false;
        });
    },
  },
  metaInfo() {
    return {
      title: this.$t('Auth.Register.PageTitle'),
    };
  },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <b-overlay :show="loading" spinner-variant="primary" rounded="sm">
          <div class="card overflow-hidden">
            <div class="bg-soft bg-primary">
              <div class="row">
                <div class="col-12">
                  <div class="text-dark p-4 text-center">
                    <h5>{{ $t('Auth.Register.Title') }}</h5>
                    <p>{{ $t('Auth.Register.Subtitle', { name: appName }) }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div>
                <div class="avatar-md profile-user-wid mb-4 mx-auto">
                  <span class="avatar-title rounded-circle bg-light">
                    <img :src="$getConfigValue('logoPath', 'VUE_APP_LOGO_URL')" alt height="42" />
                  </span>
                </div>
              </div>

              <b-alert :show="!!regErrorText" class="mt-3" variant="danger" dismissible>
                {{ regErrorText }}
              </b-alert>

              <div v-if="notification.message" :class="'alert ' + notification.type">
                {{ notification.message }}
              </div>

              <Form class="p-2" @submit="tryToRegisterIn">
                <Field name="email" rules="required|email" :label="$t('Form.Email')" v-slot="{ errors, field }">
                  <b-form-group class="mb-3" id="fullname-group" :label="$t('Form.Email')" label-for="email">
                    <b-form-input
                      id="email"
                      v-model="user.email"
                      type="email"
                      :placeholder="$t('Form.EmailPlaceholder')"
                      :class="{ 'is-invalid': errors.length }"
                      v-bind="field"
                    />

                    <ErrorMessage name="email" class="invalid-feedback" />
                  </b-form-group>
                </Field>

                <Field name="phone" rules="required|phone" :label="$t('Form.Phone')" v-slot="{ errors, field }">
                  <b-form-group class="mb-3" :label="$t('Form.Phone')" label-for="phone-field">
                    <b-form-input
                      id="phone-field"
                      v-model="user.phone"
                      type="tel"
                      placeholder="+XXXXXXXXXXXX"
                      :class="{ 'is-invalid': errors.length }"
                      v-bind="field"
                    />

                    <ErrorMessage name="phone" class="invalid-feedback" />
                  </b-form-group>
                </Field>

                <Field
                  name="first_name"
                  rules="required|max:255"
                  :label="$t('Form.FirstName')"
                  v-slot="{ errors, field }"
                >
                  <b-form-group class="mb-3" :label="$t('Form.FirstName')" label-for="first_name">
                    <b-form-input
                      id="first_name"
                      v-model="user.first_name"
                      :placeholder="$t('Form.FirstNamePlaceholder')"
                      :class="{ 'is-invalid': errors.length }"
                      v-bind="field"
                    />

                    <ErrorMessage name="first_name" class="invalid-feedback" />
                  </b-form-group>
                </Field>

                <Field
                  name="last_name"
                  rules="required|max:255"
                  :label="$t('Form.LastName')"
                  v-slot="{ errors, field }"
                >
                  <b-form-group class="mb-3" :label="$t('Form.LastName')" label-for="last_name">
                    <b-form-input
                      id="last_name"
                      v-model="user.last_name"
                      :placeholder="$t('Form.LastNamePlaceholder')"
                      :class="{ 'is-invalid': errors.length }"
                      v-bind="field"
                    />

                    <ErrorMessage name="last_name" class="invalid-feedback" />
                  </b-form-group>
                </Field>

                <Field name="password" rules="required|min:8" :label="$t('Form.Password')" v-slot="{ errors, field }">
                  <b-form-group class="mb-3" :label="$t('Form.Password')" label-for="password">
                    <b-form-input
                      id="password"
                      v-model="user.password"
                      type="password"
                      :placeholder="$t('Form.PasswordPlaceholder')"
                      :class="{ 'is-invalid': errors.length }"
                      v-bind="field"
                    />

                    <ErrorMessage name="password" class="invalid-feedback" />
                  </b-form-group>
                </Field>

                <Field
                  name="password_confirmation"
                  rules="required|confirmed:@password"
                  :label="$t('Form.Password')"
                  v-slot="{ errors, field }"
                >
                  <b-form-group class="mb-3" :label="$t('Form.PasswordConfirmation')" label-for="password_confirmation">
                    <b-form-input
                      id="password_confirmation"
                      v-model="user.password_confirmation"
                      type="password"
                      :placeholder="$t('Form.PasswordConfirmationPlaceholder')"
                      :class="{ 'is-invalid': errors.length }"
                      v-bind="field"
                    />

                    <ErrorMessage name="password_confirmation" class="invalid-feedback" />
                  </b-form-group>
                </Field>

                <div class="mt-4 d-grid">
                  <b-button type="submit" variant="dark" class="btn-block">{{ $t('Auth.Register.Register') }}</b-button>
                </div>

                <!-- Registration terms -->
                <!-- <div class="mt-4 text-center">
                  <p class="mb-0">
                    {{ $t('Auth.Register.YouAgree', { name: appName }) }}
                    <a href="javascript: void(0);" class="text-primary">{{
                      $t('Auth.Register.Terms', { name: appName })
                    }}</a>
                  </p>
                </div> -->
              </Form>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->

          <div class="mt-5 text-center">
            <p>
              {{ $t('Auth.Register.HaveAccount') }}
              <router-link to="/login" class="fw-medium text-primary">{{ $t('Auth.Register.Login') }}</router-link>
            </p>
            <div class="d-flex justify-content-center align-items-center">
              <p class="m-0">© {{ new Date().getFullYear() }}</p>
            </div>
          </div>
        </b-overlay>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
